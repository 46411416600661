import React, { useState, useEffect } from 'react';
import { Box, Heading, Image, Text } from 'theme-ui';
import { Link } from 'gatsby';
import slugify from '../../helpers/slugifyLower';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import SortBy from './Filter/SortBy';
import OrderBy from './Filter/OrderBy';
import Search from './Filter/Search';
import TagsFilter from './Filter/TagsFilter';
import Tags from './ArticleComponents/Tags';
import moment from 'moment';
import NoDataBox from './ArticleComponents/NoDataBox';
import cloudinaryString from '../../helpers/cloudinaryString';
export default function ArticleMenu({
  businessData,
  articleData,
  blogTags,
  pageObjectKey,
  customPageName,
  variantName,
  isMultiLocationSite,
  city,
}) {
  const [articleList, setArticleList] = useState(articleData?.edges);
  const [articleFilterSettings, setArticleFilterSettings] = useState({
    search: '',
    order: 'desc', // DESC or ASC
    sortBy: 'createdAt',
    tags: [],
  });

  const variant = variantName ? variantName : 'teamMenu';

  const businessLogo = cloudinaryString(
    businessData.avatar.imageBaseUrl,
    businessData.avatar.imagePrefix
  );

  // the proeprties to compare are one level deeper with node and an child field of the object.
  function dynamicSort(key, order = 'desc') {
    return function innerSort(a, b) {
      if (!a.node.hasOwnProperty(key) || !b.node.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
      // if the variable is a string lowercase if not don't other it will crash trying to lowercase a intergar
      const varA =
        typeof a.node[key] === 'string'
          ? a.node[key].toLowerCase()
          : a.node[key];

      const varB =
        typeof b.node[key] === 'string'
          ? b.node[key].toLowerCase()
          : b.node[key];

      // this is the sorting operator and moves element back or forward in the array to sort it in the right order
      let comparison = 0;
      varA > varB ? (comparison = 1) : (comparison = -1);

      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

  const updateArticleList = () => {
    // checks the articles to match the search criteria on subtitle and text only
    // opted not to do description to not slowdown the algorthrim
    const filteredData = articleData.edges.map((article) => {
      const titleMatch = article?.node?.title
        ?.toLowerCase()
        ?.includes(articleFilterSettings.search);

      const subtitleMatch = article?.node?.subtitle
        ?.toLowerCase()
        ?.includes(articleFilterSettings?.search);

      const tagMatch = articleFilterSettings?.tags?.filter((element) =>
        article?.node?.tags?.map((str) => str.toLowerCase()).includes(element)
      );

      const noTagsSelected = articleFilterSettings?.tags?.length === 0;

      const foundMatchingTags = tagMatch?.length > 0;

      const tagFilterMatch = noTagsSelected ? true : foundMatchingTags;

      if ((titleMatch && tagFilterMatch) || (subtitleMatch && tagFilterMatch)) {
        return article;
      }
    });

    // takes the searched data and filters it by sort by and order
    filteredData.sort(
      dynamicSort(articleFilterSettings.sortBy, articleFilterSettings.order)
    );

    // then set that data to state to display on the UI
    setArticleList(filteredData.filter((e) => e)); // returns a list with undefineds in it so filter thoose out using filter
  };

  useEffect(() => {
    if (pageObjectKey === 'blog') {
      updateArticleList(); //* only apply to blog
    }
    return () => {};
  }, [articleFilterSettings]);

  return (
    <Box
      sx={{ variant: `customVariants.${pageObjectKey}Menu` }}
      variant={`${variant}.container`}
      className='articleMenuContainer'
    >
      {pageObjectKey === 'blog' && (
        <Box variant={`${variant}.filters`}>
          <Heading variant={`${variant}.filtersTitle`} className='filtersTitle'>
            Filters
          </Heading>

          <Text
            variant={`${variant}.numArticles`}
          >{`Displaying ${articleList?.length} of ${articleData?.edges?.length}`}</Text>

          <Search
            variant={variant}
            setArticleFilterSettings={setArticleFilterSettings}
            value={articleFilterSettings.search}
            articleFilterSettings={articleFilterSettings}
          />
          <SortBy
            variant={variant}
            setArticleFilterSettings={setArticleFilterSettings}
            value={articleFilterSettings.sortBy}
            articleFilterSettings={articleFilterSettings}
          />
          <OrderBy
            variant={variant}
            setArticleFilterSettings={setArticleFilterSettings}
            articleFilterSettings={articleFilterSettings}
            value={articleFilterSettings.order}
          />
          {pageObjectKey === 'blog' && (
            <TagsFilter
              variant={variant}
              setArticleFilterSettings={setArticleFilterSettings}
              articleFilterSettings={articleFilterSettings}
              value={articleFilterSettings.tags}
              tags={blogTags}
            />
          )}
        </Box>
      )}

      <Box variant={`${variant}.blogsContainer`} className='blogsContainer'>
        <Heading
          as='h2'
          variant={`${variant}.boxesHeading`}
          className='boxesHeading'
        >
          {customPageName ? customPageName : pageObjectKey}
        </Heading>

        <Box variant={`${variant}.boxesContainer`} className='boxesContainer'>
          {articleList?.length > 0 ? (
            articleList.map((article, index) => {
              let articleLink = `${
                isMultiLocationSite ? '/' + slugify(city) : ''
              }/${customPageName ? customPageName : pageObjectKey}/${
                article.node.title ? slugify(article.node.title) : ''
              }`;

              let isExternalLink = false;
              const ctaLinkUrl = article?.node?.ctaLink?.url;
              const hasNoBodyText =
                article?.node?.bodyPlainText?.length < 6 ? true : false;
              // if the page is press and there is a url link and the body is empty just link to article instead of page.
              if (
                // pageObjectKey === "press" &&
                ctaLinkUrl &&
                hasNoBodyText
              ) {
                articleLink = article?.node?.ctaLink?.url;
                isExternalLink = true;
              }

              return (
                <Box
                  variant={`${variant}.box`}
                  key={`article${pageObjectKey}-${index}`}
                  className='box'
                >
                  <Link
                    to={articleLink}
                    target={isExternalLink ? '_blank' : ''}
                    style={
                      hasNoBodyText && pageObjectKey === 'team' // ? if the member has no body to show then don't let them click it to see the page.
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                  >
                    {article.node?.media[0]?.cloudinaryId && (
                      <Box
                        variant={`${variant}.imageContainer`}
                        className='imageContainer'
                      >
                        <Box
                          variant={`${variant}.imageFill`}
                          className='imageFill'
                        />
                        <Image
                          variant={`${variant}.image`}
                          src={`https://res.cloudinary.com/gonation/${article.node?.media[0].cloudinaryId}`}
                        />
                      </Box>
                    )}
                    <Box
                      variant={`${variant}.textContent`}
                      className='textContent'
                    >
                      <Heading
                        as='h3'
                        className='title'
                        variant={`${variant}.title`}
                      >
                        {article.node.title}
                      </Heading>
                      <Heading
                        as='h4'
                        className='subtitle'
                        variant={`${variant}.subtitle`}
                      >
                        {article?.node?.subtitle?.length > 100
                          ? `${article.node.subtitle.substring(0, 100)}...`
                          : article.node.subtitle}
                      </Heading>

                      <Text as='p' className='text' variant={`${variant}.text`}>
                        {article.node.description}
                      </Text>
                      {article.node.tags && (
                        <Tags tags={article.node.tags} variant={variant} />
                      )}

                      {article.node.author && (
                        <Text
                          as='p'
                          className='author'
                          variant={`${variant}.author`}
                        >
                          By {article.node.author}
                        </Text>
                      )}

                      {pageObjectKey !== 'team' && (
                        <Box
                          variant={`${variant}.timeContainer`}
                          className='timeContainer'
                        >
                          <Text
                            variant={`${variant}.readingTime`}
                            className='readingTime'
                          >
                            <FontAwesomeIcon icon={faClock} />{' '}
                            {article.node.readingTimeText}
                          </Text>
                          <Text
                            variant={`${variant}.createdAt`}
                            className='createdAt'
                          >
                            <FontAwesomeIcon icon={faCalendarAlt} />{' '}
                            {moment(article.node.createdAt).format(
                              'DD MMM, YYYY'
                            )}
                          </Text>
                        </Box>
                      )}
                    </Box>
                  </Link>
                </Box>
              );
            })
          ) : (
            <NoDataBox
              pageObjectKey={pageObjectKey}
              variant={variant}
              businessLogo={businessLogo}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
